import React from "react";
import { TermsContainer, TermsHeader, TermsContent } from "./Term.elements";

const Term = () => {
  return (
    <TermsContainer>
      <TermsHeader>شروط الاستخدام</TermsHeader>
      <TermsContent>
        <p>
          <strong>آخر تحديث:</strong> 20 سبتمبر 2024
        </p>

        {/* مقدمة */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>مقدمة</h2>
          <p>
            هذه الشروط والأحكام ("الشروط") تحكم استخدامك لتطبيق FluentFox
            ("التطبيق"). يرجى قراءة هذه الشروط بعناية قبل البدء في استخدام
            التطبيق.
          </p>
        </div>

        {/* قبول الشروط */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>قبول الشروط</h2>
          <p>
            باستخدامك للتطبيق، فإنك توافق على الالتزام بهذه الشروط وجميع
            القوانين واللوائح المعمول بها. إذا كنت لا توافق على أي من هذه
            الشروط، يرجى عدم استخدام التطبيق.
          </p>
        </div>

        {/* تعريفات */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>تعريفات</h2>
          <ul>
            <li>
              <strong>التطبيق:</strong> يشير إلى تطبيق FluentFox المُقدم من شركة
              سعود خالد فهد السلوم للتجارة المحدودة.
            </li>
            <li>
              <strong>المستخدم:</strong> يشير إلى أي فرد يقوم بتنزيل أو استخدام
              التطبيق.
            </li>
            <li>
              <strong>الشروط:</strong> تشير إلى هذه الشروط والأحكام.
            </li>
          </ul>
        </div>

        {/* استخدام التطبيق */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>استخدام التطبيق</h2>
          <p>
            <strong>1. الأهلية:</strong>
          </p>
          <ul>
            <li>
              يجب أن تكون في السن القانوني وفقًا للقوانين المعمول بها في منطقتك
              لاستخدام هذا التطبيق.
            </li>
          </ul>
          <p>
            <strong>2. الترخيص:</strong>
          </p>
          <ul>
            <li>
              نمنحك ترخيصًا محدودًا وغير حصري وغير قابل للتحويل للوصول إلى
              التطبيق واستخدامه للأغراض الشخصية وغير التجارية.
            </li>
          </ul>
        </div>

        {/* حقوق الملكية الفكرية */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>حقوق الملكية الفكرية</h2>
          <ul>
            <li>
              جميع الحقوق والعناوين والمصالح في التطبيق ومحتوياته مملوكة لشركة
              سعود خالد فهد السلوم للتجارة المحدودة أو المرخصين لها.
            </li>
            <li>
              يُحظر نسخ أو تعديل أو توزيع أو بيع أو تأجير أي جزء من التطبيق دون
              إذن كتابي مسبق منا.
            </li>
          </ul>
        </div>

        {/* سلوك المستخدم */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>سلوك المستخدم</h2>
          <p>أنت توافق على عدم:</p>
          <ul>
            <li>استخدام التطبيق لأي أغراض غير قانونية أو غير مصرح بها.</li>
            <li>محاولة الوصول غير المصرح به إلى أنظمتنا أو شبكاتنا.</li>
            <li>نشر أو نقل أي مواد ضارة أو مسيئة أو تشهيرية أو غير لائقة.</li>
          </ul>
        </div>

        {/* الاشتراكات والمشتريات داخل التطبيق */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>الاشتراكات والمشتريات داخل التطبيق</h2>
          <ul>
            <li>
              <strong>الدفع:</strong> قد يتطلب الوصول إلى ميزات معينة دفع رسوم
              اشتراك.
            </li>
            <li>
              <strong>التجديد التلقائي:</strong> ستتجدد الاشتراكات تلقائيًا ما
              لم يتم إلغاؤها قبل نهاية فترة الاشتراك الحالية.
            </li>
            <li>
              <strong>سياسة الإلغاء:</strong> يمكنك إدارة أو إلغاء اشتراكك من
              خلال إعدادات متجر التطبيقات على جهازك.
            </li>
          </ul>
        </div>

        {/* إخلاء المسؤولية */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>إخلاء المسؤولية</h2>
          <ul>
            <li>
              يتم توفير التطبيق "كما هو" دون أي ضمانات من أي نوع، سواء كانت
              صريحة أو ضمنية.
            </li>
            <li>
              نحن لا نضمن أن يكون التطبيق خاليًا من الأخطاء أو الفيروسات أو
              المكونات الضارة الأخرى.
            </li>
          </ul>
        </div>

        {/* تحديد المسؤولية */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>تحديد المسؤولية</h2>
          <ul>
            <li>
              لن نكون مسؤولين عن أي أضرار مباشرة أو غير مباشرة أو عرضية أو خاصة
              أو تبعية ناتجة عن استخدامك أو عدم قدرتك على استخدام التطبيق.
            </li>
          </ul>
        </div>

        {/* إنهاء الاتفاقية */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>إنهاء الاتفاقية</h2>
          <ul>
            <li>
              نحتفظ بالحق في إنهاء أو تعليق وصولك إلى التطبيق فورًا دون إشعار
              مسبق إذا خالفت أيًا من شروط هذه الاتفاقية. عند إنهاء الاتفاقية،
              يجب عليك التوقف عن استخدام التطبيق وحذف جميع النسخ من التطبيق من
              أجهزتك.
            </li>
          </ul>
        </div>

        {/* التعويض */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>التعويض</h2>
          <ul>
            <li>
              توافق على تعويض وحماية شركة سعود خالد فهد السلوم للتجارة المحدودة
              وأعضائها ومديريها ووكلائها من وأي مطالبات أو خسائر أو أضرار أو
              مسؤوليات أو تكاليف أو نفقات (بما في ذلك الرسوم القانونية) الناتجة
              عن أو الناشئة عن:
            </li>
            <li>استخدامك للتطبيق بشكل مخالف لهذه الشروط.</li>
            <li>خرقك لأي من حقوق الطرف الثالث.</li>
            <li>أي نشاط غير قانوني تقوم به أثناء استخدام التطبيق.</li>
          </ul>
        </div>

        {/* تعديلات على الشروط */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>تعديلات على الشروط</h2>
          <ul>
            <li>
              نحتفظ بالحق في تعديل هذه الشروط في أي وقت. سيتم نشر التغييرات على
              هذه الصفحة مع تحديث تاريخ "آخر تحديث".
            </li>
            <li>
              يعتبر استمرارك في استخدام التطبيق بعد نشر التغييرات قبولًا منك
              للشروط المعدلة.
            </li>
          </ul>
        </div>

        {/* سياسة الخصوصية */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>سياسة الخصوصية</h2>
          <p>
            يرجى مراجعة{" "}
            <a
              href="https://www.yourdomain.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              سياسة الخصوصية
            </a>{" "}
            الخاصة بنا لفهم ممارساتنا المتعلقة بجمع واستخدام وحماية معلوماتك
            الشخصية.
          </p>
        </div>

        {/* القانون الحاكم */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>القانون الحاكم</h2>
          <ul>
            <li>
              تخضع هذه الشروط وتُفسر وفقًا لقوانين المملكة العربية السعودية.
            </li>
          </ul>
        </div>

        {/* تواصل معنا */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>تواصل معنا</h2>
          <p>إذا كان لديك أي أسئلة حول هذه الشروط، يرجى التواصل معنا عبر:</p>
          <ul>
            <li>
              <strong>البريد الإلكتروني:</strong>{" "}
              <a href="mailto:support@fluentfox.com">support@fluentfox.com</a>
            </li>
            <li>
              <strong>العنوان:</strong> شركة سعود خالد فهد السلوم للتجارة
              المحدودة، 1234 شارع السوق، الرياض، المملكة العربية السعودية
            </li>
          </ul>
        </div>

        {/* ملاحظة */}
        <div style={{ marginBottom: "1.5em" }}>
          <h2>ملاحظة</h2>
          <p>
            باستخدامك للتطبيق، فإنك تقر بأنك قرأت هذه الشروط وتوافق على الالتزام
            بها.
          </p>
        </div>
      </TermsContent>
    </TermsContainer>
  );
};

export default Term;
